<!--
 * @Author: gaojingran
 * @Date: 2021-04-06 11:35:26
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-08-11 10:51:24
 * @Description: 按钮上传
-->
<style lang="less" scoped>
.neo-upload-button {
  .file-tag-list {
    margin-top: 10px;
    /deep/.ant-space {
      flex-wrap: wrap;
    }
    .file-tag-item {
      max-width: 120px;
      cursor: pointer;
      &:hover {
        .icon-close {
          display: block !important;
        }
      }
      .file-name {
        font-size: @font_size_2;
        color: @text_color_2;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 0;
        line-height: 15px;
      }
      .action-icon {
        text-align: center;
        font-size: @font_size_1;
        color: @text_color_2;
        line-height: 20px;
        .icon-close {
          display: none;
        }
      }
    }
  }
}
</style>

<template>
  <div class="neo-upload-button">
    <a-upload
      :showUploadList="false"
      :multiple="multiple"
      :accept="accept"
      :file-list="value"
      :beforeUpload="handleBeforeUpload"
      :disabled="value.length >= maxLength"
      :customRequest="customRequest"
    >
      <a-button
        :type="neoStyle ? undefined : 'primary'"
        :class="({ 'neo-btn-primary': neoStyle }, className)"
        :style="btnStyle"
        :disabled="value.length >= maxLength"
        :loading="loading"
      >
        <a-icon v-if="showIcon" type="upload" /> {{ title }}
      </a-button>
    </a-upload>
    <p class="neo-upload-note mt-2" v-if="!value.length && showNote">
      {{ note }}
    </p>
    <!-- file List 展示方式 Tag  -->
    <template v-if="value.length && showFileList">
      <div class="file-tag-list">
        <a-space :size="10">
          <div class="file-tag-item" v-for="item in value" :key="item.uid">
            <p class="file-name" :class="item.status === 'error' ? 'error' : 'c-2'" :title="item.name">
              {{ item.name }}
            </p>
            <div class="action-icon">
              <a-space :size="10">
                <a-icon v-if="item.status === 'uploading'" type="loading" />
                <a-icon class="icon-close" type="close" @click="handleDeleteFile(item.uid)" />
              </a-space>
            </div>
          </div>
        </a-space>
      </div>
    </template>
  </div>
</template>

<script>
import withAliOss from '@/mixins/withAliOss'

export default {
  name: 'NeoUploadButton',
  mixins: [withAliOss],
  props: {
    /**
     *  文件列表 object[]
     *  uid
        name      // 文件名
        status    // 状态有：uploading done error
        percent   // 上传进度
        url       // 下载链接
     * */
    value: {
      type: Array,
      default: () => {},
    },
    // 数据排序方式
    // sortType: {
    //   type: String,
    //   default: 'push',
    //   validator(value) {
    //     return ['push', 'unshift'].indexOf(value) !== -1
    //   },
    // },
    // 蓝色线框按钮样式
    neoStyle: {
      type: Boolean,
      default: false,
    },
    // 按钮名称
    title: {
      type: String,
      default() {
        return this.$t('uploadButton.title')
      },
    },
    // note
    note: {
      type: String,
      default() {
        return this.$t('validate.upload_number_size', { number: 999, size: 999 })
      },
    },
    // 接受上传的文件类型 https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#unique_file_type_specifiers
    accept: {
      type: String,
      default: '',
    },
    // 最大多少文件
    maxLength: {
      type: Number,
      default: 20,
    },
    // 单文件大小 单位 M
    maxSize: {
      type: Number,
      default: 200,
    },
    // 是否展示note
    showNote: {
      type: Boolean,
      default: true,
    },
    // 展示上传的文件
    showFileList: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: '',
    },
    btnStyle: {
      type: String,
      default: '',
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    // 是否支持多选
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // beforeUpload
    handleBeforeUpload(file) {
      console.log('选择文件之后', file)
      const { name, size } = file
      if (this.accept) {
        const suffix = name.slice(name.lastIndexOf('.')).toLowerCase()
        if (
          this.accept
            .split(',')
            .map((v) => v.trim())
            .indexOf(suffix) < 0
        ) {
          this.$message.error(this.$t('prompt.upload_illegal_format', { name }))
          return false
        }
      }
      if (size / 1024 / 1024 > this.maxSize) {
        this.$message.error(this.$t('validate.upload_max_size', { filename: name, size: this.maxSize }))
        return false
      }
    },
  },
}
</script>
